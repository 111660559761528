import { throttle, debounce } from 'throttle-debounce'

let defOverflowValue = ''
let defScrollbarWidth = ''

function getScrollbarWidth() {
  const r = window.innerWidth - document.documentElement.clientWidth
  return r
}

function setScrollbarWidth() {
  const w = getScrollbarWidth()
  document.documentElement.style.setProperty('--scrollbar-width', w + 'px')
}

function checkScrollbarWidth(mutationsList, observer) {
  const overflowValue = window.document.documentElement.style.overflowY
  const scrollbarWidth = getScrollbarWidth()

  if (
    defOverflowValue !== overflowValue ||
    defScrollbarWidth !== scrollbarWidth
  ) {
    setScrollbarWidth()
    defOverflowValue = overflowValue
    defScrollbarWidth = scrollbarWidth
  }
}

function init() {
  setScrollbarWidth()
  var observer = new MutationObserver(throttle(250, checkScrollbarWidth))
  observer.observe(document.documentElement, {
    attributes: true,
    subtree: true,
  })
  window.addEventListener('scroll', throttle(250, checkScrollbarWidth), false)
  window.addEventListener('resize', throttle(250, checkScrollbarWidth), false)
}

function ready() {
  // setScrollbarWidth();
}

export default {
  init() {
    init()

    /*document.addEventListener('DOMContentLoaded', function() {
      ready();
    });*/
  },
}
